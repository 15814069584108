import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, UrlTree } from '@angular/router';
import { map, Observable, of, switchMap } from 'rxjs';

import { ROLES } from 'app/constants/auth.interface';

import { MAuthService } from '@mercadona/core/auth';
import { MPageErrorService } from '@mercadona/core-ui/page-error';

/* eslint-disable*/

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  /**
   * Class constructor
   *
   * @param {MAuthService} mAuthService MAuthService
   * @param {MPageErrorService} mPageErrorService error page config
   */
  constructor(private mAuthService: MAuthService, private mPageErrorService: MPageErrorService) {}
  canLoad(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkPermissions();
  }

  /**
   * Can Activate method
   *
   * @returns {boolean} boolean
   */
  canActivate(): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.checkPermissions();
  }

  /**
   * Validates if user has some permission
   *
   * @returns {Observable<boolean>} validation
   */
  checkPermissions() {
    return this.mAuthService.isAuthenticated$.pipe(
      switchMap((res) => {
        if (res) {
          return this.mAuthService.hasSomePermission(Object.values(ROLES)).pipe(
            map((response) => {
              if (!response) {
                this.mPageErrorService.navigateToError({
                  title: 'error.title',
                  subtitle: 'error.subtitle',
                  message: 'error.message'
                });
              }
              return response;
            })
          );
        }
        return of(false);
      })
    );
  }
}
