<m-header showLogo class="sticky">
  <span class="cursor-pointer" m-header-title [routerLink]="utils.home">{{ 'title' | mTranslate }}</span>
  <m-header-actions>
    <button m-button color="base" buttonType="icon" [m-context-menu-trigger-for]="languageMenu" size="small">
      <m-icon iconName="regular/interface-essential/text-options/translate"></m-icon>
    </button>
    <button
      *mHasSomePermission="[utils.rolesGetter.administrador]"
      m-button
      m-list-item-action
      buttonType="icon"
      color="base"
      size="small">
      <m-icon iconName="regular/interface-essential/settings/cog"></m-icon>
    </button>
    <button (click)="handleLogoutButtonClick()" m-button m-list-item-action buttonType="icon" color="base" size="small">
      <m-icon iconName="regular/interface-essential/login-logout/logout-1"></m-icon>
    </button>
    <span>{{ userName }}</span>
    <div class="img-profile-container" *ngIf="!isProviderB2C">
      <img alt="Usuario" [src]="imageProfile | emptyImgProfile" />
    </div>
  </m-header-actions>
</m-header>

<m-context-menu #languageMenu [xPosition]="posX" [yPosition]="posY">
  <m-context-menu-item
    [selected]="language.selected"
    (click)="selectLanguage(language)"
    *ngFor="let language of languages; let i = index; trackBy: trackByFn">
    {{ 'selected-language.' + language.title | mTranslate }}
  </m-context-menu-item>
</m-context-menu>
