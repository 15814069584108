export const ROLES = {
  propietario: 'CdV90 GO Propietario',
  gCompras: 'CdV90 GO GCompras',
  proveedor: 'CdV90 GO Proveedor',
  proveedorB2C: 'CdV90-Proveedores',
  contable: 'CdV90 GO Contable',
  fiscal: 'CdV90 GO Fiscal',
  aprovisionamiento: 'CdV90 GO Aprovisionamiento',
  lector: 'CdV90 GO Lector',
  gestor: 'CdV90 GO Gestor',
  administrador: 'CdV90 GO Administrador'
};
