import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { IChartsResponse, INeedsChart } from 'app/repository/models/home.models';
import { HomeService } from 'app/repository/services/home/home.service';

import {
  GetProductListRequestBodyType,
  HomeApiServiceAbstraction,
  IHomeApiService
} from '../home-api/home-api.service';

@Injectable({
  providedIn: 'root'
})
export class HomeAccountantApiService extends HomeApiServiceAbstraction implements IHomeApiService {
  constructor(homeService: HomeService) {
    super(homeService);
  }

  getChartsHome(): Observable<IChartsResponse> {
    return this.homeService.getAccountantCharts().pipe(
      map((res) => ({
        data: [
          {
            chartType: 'T' as string,
            needs: undefined as unknown as INeedsChart,
            products: {
              done: res.data.covered,
              pending: res.data.pending,
              donePercentage: res.data.coveredPercentage
            }
          }
        ]
      }))
    );
  }

  getProductsList(body: GetProductListRequestBodyType) {
    return this.homeService.getAccountantVariableArticles(
      body.search,
      body.pageNumber,
      body.pageSize,
      body.statusId,
      body.missingAccountingInfo
    );
  }
}
