import { InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const SUPPLIER_TOKEN = new InjectionToken<
  BehaviorSubject<
    | {
        supplierName: string;
        supplierId: string;
      }
    | undefined
  >
>('SUPPLIER_TOKEN');
