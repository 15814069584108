import { Injectable } from '@angular/core';

import { AssortmentService } from 'app/repository/services/assortment/assortment.service';

/**
 * AssortmentApiService
 */
@Injectable({
  providedIn: 'root'
})
export class AssortmentApiService {
  /**
   * Class constructor
   *
   * @param {AssortmentService} assortmentService assortment service
   */
  constructor(private assortmentService: AssortmentService) {}

  updateCostCenterAssortment(operation: 'variableArticleId' | 'needId', id: string) {
    return this.assortmentService.updateCostCenterAssortment(operation, id);
  }

  getSupplierName() {
    return this.assortmentService.getSupplierName();
  }
}
