import { Injectable, Type } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

import { NgCacheRouteReuseStoreService } from './ng-cache-route-reuse-store.service';

@Injectable()
export class NgCacheRouteReuseStrategy implements RouteReuseStrategy {
  constructor(private storeService: NgCacheRouteReuseStoreService) {}

  private shouldBeReused(route: ActivatedRouteSnapshot): boolean {
    // eslint-disable-next-line no-implicit-coercion
    return !!route.data.reuse;
  }

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this.shouldBeReused(route);
  }

  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (handle) {
      this.storeService.set(route.component as unknown as string | Type<unknown>, handle);
    } else {
      this.storeService.delete(route.component as unknown as string | Type<unknown>);
    }
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this.shouldBeReused(route)
      ? this.storeService.has(route.component as unknown as string | Type<unknown>)
      : false;
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this.shouldBeReused(route)
      ? this.storeService.get(route.component as unknown as string | Type<unknown>)
      : null;
  }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === current.routeConfig;
  }
}
