import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { APP_CONFIG } from '@constants';
import { environment } from '@environment';
import { NgCacheRouteReuseModule } from '@services/cache-route-reuse/ng-cache-route-reuse.module';

import { MSpinnerGlobalService, MSpinnerModule } from '@mercadona/components/spinner';
import { MAuthModule } from '@mercadona/core/auth';
import { MLoggerModule } from '@mercadona/core/logger';
import { MPlatformModule } from '@mercadona/core/platform';
import { MTelemetryModule } from '@mercadona/core/telemetry';
import { MTranslateModule } from '@mercadona/core/translate';
import { MErrorHandlerModule } from '@mercadona/core-ui/error-handler';
import { MPageErrorModule } from '@mercadona/core-ui/page-error';
import { MPageNotFoundModule } from '@mercadona/core-ui/page-not-found';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LanguageInterceptor } from './interceptors/language.interceptor';
import { NameLanguagePipe } from './repository/pipes/name-language.pipe';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    DragDropModule,
    HttpClientModule,
    MErrorHandlerModule.forRoot(),
    MTranslateModule.forRoot(APP_CONFIG.language),
    MLoggerModule.forRoot({
      logLevel: environment.logLevel
    }),
    MPlatformModule.forRoot({
      appName: APP_CONFIG.appName,
      environment: environment.env
    }),
    MPageNotFoundModule,
    MPageErrorModule.forRoot(),
    MTelemetryModule.forRoot({
      url: environment.telemetryConfig.url,
      traces: environment.telemetryConfig.traces
    }),
    MAuthModule.forRoot(
      (() => {
        if (window.location.pathname.includes('/cdv90/supplier')) {
          return {
            authOnAppStart: true,
            forceAuthentication: true,
            clientId: environment.azure.clientId,
            authority: environment.azure.authority,
            knownAuthorities: [environment.azure.authority],
            scope: environment.azure.scope,
            postLogoutRedirectUri: environment.azure.postLogoutRedirectUri,
            redirectUri: environment.azure.redirectUri,
            restServices: [environment.restServiceExternal]
          };
        }
        return {
          authOnAppStart: true,
          forceAuthentication: false,
          clientId: environment.adfs.clientId,
          authority: environment.adfs.authority,
          knownAuthorities: [environment.adfs.authority],
          scope: ['openid'],
          resource: 'api://mercadona/audience/services/cnv/v1',
          postLogoutRedirectUri: environment.adfs.postLogoutRedirectUri,
          redirectUri: environment.adfs.redirectUri,
          navigateToLoginRequestUrl: false,
          restServices: [environment.restServiceArticlesCNV]
        };
      })()
    ),
    AppRoutingModule,
    MSpinnerModule,
    NgCacheRouteReuseModule
  ],
  providers: [
    NameLanguagePipe,
    FormBuilder,
    MSpinnerGlobalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
