import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ENDPOINTS, ENDPOINTS_B2C } from 'app/config/endpoints';

/**
 * AssortmentService
 */
@Injectable({
  providedIn: 'root'
})
export class AssortmentService {
  /**
   * Class constructor
   *
   * @param {HttpClient} httpClient http client
   */
  constructor(private httpClient: HttpClient) {}

  updateCostCenterAssortment(operation: 'variableArticleId' | 'needId', id: string) {
    const url: string = `${ENDPOINTS.costCenterAssortment}?${operation}=${id}`;
    return this.httpClient.post<object>(url, {});
  }

  getSupplierName() {
    const url: string = `${ENDPOINTS_B2C.getSupplierName}`;
    return this.httpClient.get<{ data: { supplierName: string; supplierId: string } }>(url);
  }
}
