import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { APP_CONFIG } from '@constants';
import { PipeModule } from 'app/repository/pipes/pipe.module';

import { MBadgeModule } from '@mercadona/components/badge';
import { MButtonModule } from '@mercadona/components/button';
import { MContextMenuModule } from '@mercadona/components/context-menu';
import { MDialogModule } from '@mercadona/components/dialog';
import { MHeaderModule } from '@mercadona/components/header';
import { MLinkModule } from '@mercadona/components/link';
import { MTooltipModule } from '@mercadona/components/tooltip';
import { MAuthModule } from '@mercadona/core/auth';
import { MTranslateModule } from '@mercadona/core/translate';
import { MIconModule } from '@mercadona/icons';

import { HeaderComponent } from './header.component';

/**
 * HeaderModule
 */
@NgModule({
  declarations: [HeaderComponent],
  imports: [
    PipeModule,
    CommonModule,
    MAuthModule,
    MHeaderModule,
    RouterModule,
    MLinkModule,
    MDialogModule,
    MButtonModule,
    MIconModule.forRoot({
      registry: 'folder',
      icons: [
        'regular/interface-essential/text-options/translate',
        'regular/interface-essential/login-logout/logout-1',
        'regular/interface-essential/settings/cog'
      ],
      prodMode: false
    }),
    MTooltipModule,
    MContextMenuModule,
    MTranslateModule.forRoot(APP_CONFIG.language),
    MBadgeModule
  ],
  exports: [HeaderComponent]
})
export class HeaderModule {}
