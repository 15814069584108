import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ENDPOINTS } from 'app/config/endpoints';
import {
  IGeographicalBoundariesBody,
  IScopeTypeBody,
  IScopeTypeResponse
} from 'app/repository/models/geographical-boundaries.models';

/**
 * GeographicalBoundariesService
 */
@Injectable({
  providedIn: 'root'
})
export class GeographicalBoundariesService {
  /**
   * Class constructor
   *
   * @param {HttpClient} httpClient http client
   */
  constructor(private httpClient: HttpClient) {}

  getScopeTypes(body: IScopeTypeBody) {
    const url: string = ENDPOINTS.scopeTypes;
    return this.httpClient.post<IScopeTypeResponse>(url, body);
  }

  getGeographicalBoundaries(body: IGeographicalBoundariesBody) {
    const url: string = ENDPOINTS.geographicalBoundaries;
    return this.httpClient.post<IScopeTypeResponse>(url, body);
  }
}
