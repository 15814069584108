import { Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';

import { NgCacheRouteReuseStoreService, StoreActionType } from './ng-cache-route-reuse-store.service';

/**
 * Provides access to reuse route's component attach/detach hooks
 * and detached routes store
 */
@Injectable()
export class NgCacheRouteReuseService {
  private static instance: NgCacheRouteReuseService = null as unknown as NgCacheRouteReuseService;

  constructor(private storeService: NgCacheRouteReuseStoreService) {}

  public static getInstance(): NgCacheRouteReuseService {
    if (!NgCacheRouteReuseService.instance) {
      const storeService = NgCacheRouteReuseStoreService.getInstance();
      const instance = new NgCacheRouteReuseService(storeService);

      NgCacheRouteReuseService.instance = instance;
    }

    return NgCacheRouteReuseService.instance;
  }

  /**
   * `Attach` component lifecycle hook.
   *
   * @param {string | Type<unknown>} component component
   * @returns {Observable<string | Type<unknown>>} response response
   * Triggers on component reattach - re-navigate on reuse route.
   */
  public onAttach(component: string | Type<unknown>): Observable<string | Type<unknown>> {
    return this.storeService.on(StoreActionType.Delete, component);
  }

  /**
   * `Detach` component lifecycle hook.
   *
   * @param {string | Type<unknown>} component component
   * @returns {Observable<string | Type<unknown>>} response response
   * Triggers on component detach - navigate from reuse route.
   */
  public onDetach(component: string | Type<unknown>): Observable<string | Type<unknown>> {
    return this.storeService.on(StoreActionType.Set, component);
  }

  /**
   * Check whether component is attached
   *
   * @param {string | Type<unknown>} component component
   * @returns {boolean} response response
   */
  public isAttached(component: string | Type<unknown>): boolean {
    return !this.storeService.has(component);
  }

  /**
   * Check whether component is detached
   *
   * @param {string | Type<unknown>} component component
   * @returns {boolean} response response
   */
  public isDetached(component: string | Type<unknown>): boolean {
    return this.storeService.has(component);
  }

  /**
   * Delete a reuse route's component from cache
   *
   * @param {string | Type<unknown>} component component
   * @returns {boolean} response response
   */
  public delete(component: string | Type<unknown>): boolean {
    return this.storeService.delete(component, { emitEvent: false });
  }

  /**
   * Clear the reuse route components cache
   *
   * @returns {void} void void
   */
  public clear(): void {
    return this.storeService.clear();
  }
}
