import { MLoggerLevel } from '@mercadona/core/logger';
import { MPlatformEnvironment } from '@mercadona/core/platform';
import { MTelemetryConfig, MTraces } from '@mercadona/core/telemetry';
import { MEnviroment } from '@mercadona/core/utils/environment';

/**
 * Do not duplicate this file into environment.dev.ts, environment.itg.ts, ...
 * Define here ONLY localhost environment variables.
 */
/**
 * For dev,itg,pre,pro environments copy this content to the chart files
 * charts/env/values-<env>.yaml
 */
const localEnvironment = {
  production: false,
  logLevel: MLoggerLevel.TRACE,
  env: 'local' as MPlatformEnvironment,
  adfs: {
    clientId: '5e842bbd-a38f-4230-8440-daa8f0cbea86',
    authority: 'https://fed.itgmercadona.com/adfs',
    stsServer: 'https://fed.itgmercadona.com/adfs',
    resource: 'api://mercadona/audience/services/cnv/v1',
    postLogoutRedirectUri: 'http://localhost:4200/cdv90',
    redirectUri: 'http://localhost:4200/cdv90',
    authOnAppStart: false
  },
  azure: {
    clientId: '85b32ef7-0c06-45b1-a016-7d4416fd49ac',
    authority: 'https://B2CBoteProv.b2clogin.com/B2CBoteProv.onmicrosoft.com/B2C_1_CNV_Login_Proveedor',
    knownAuthorities: ['https://B2CBoteProv.b2clogin.com/B2CBoteProv.onmicrosoft.com/B2C_1_CNV_Login_Proveedor'],
    scope: ['https://b2cboteprov.onmicrosoft.com/mercadona/audience/services/cnv/v1/openid'],
    postLogoutRedirectUri: 'http://localhost:4200/cdv90/supplier',
    redirectUri: 'http://localhost:4200/cdv90/supplier',
    authOnAppStart: false
  },
  restServiceArticlesCNVAzure: {
    url: 'api-dev-cna-base-path',
    scope: ['openid'],
    resource: 'https://boteprovb2c.onmicrosoft.com/mercadona/audience/services/cnv/v1'
  },
  restServiceExternal: {
    url: 'https://api.itg.mercadona.com/technical/cnv/v1',
    scope: ['openid'],
    resource: 'https://boteprovb2c.onmicrosoft.com/mercadona/audience/services/cnv/v1'
  },
  restServiceArticlesCNV: {
    url: 'api-dev-cna-base-path',
    scope: ['openid'],
    resource: 'api://mercadona/audience/services/cnv/v1'
  },
  apiBaseCnaPath: 'api-dev-cna-base-path',
  apiBaseCnaPathExt: 'https://api.itg.mercadona.com/technical/cnv/v1',
  telemetryConfig: {
    url: 'https://otelcol-front.dev.gcp.mercadona.com/v1/traces',
    traces: MTraces.DEFAULT
  } as MTelemetryConfig
};

const envInstance = new MEnviroment('cnv-front-cv90', localEnvironment);

export const environment = envInstance.environment;
