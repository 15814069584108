import { Pipe, PipeTransform } from '@angular/core';

import { LanguageSelectorService } from '@services/language-selector.service';
import { NullUndefinedType } from 'app/types/global.types';

import { INames } from '../models/home.models';

@Pipe({
  name: 'nameLanguage',
  pure: false
})
export class NameLanguagePipe implements PipeTransform {
  currentLanguage: string = 'es-ES';

  constructor(private languageSelectorService: LanguageSelectorService) {
    this.languageSelectorService.currentLanguage$.subscribe((language) => {
      this.currentLanguage = language;
    });
  }

  transform(names: INames[] | NullUndefinedType): string {
    if (names !== null && names !== undefined) {
      const locale = this.currentLanguage;
      const nameObj = names.find((n) => n.localeLanguage === locale);

      if (nameObj) {
        return nameObj.name;
      }
      const defaultNameObj = names.find((n) => n.localeLanguage === 'es-ES');
      if (defaultNameObj) {
        return defaultNameObj.name;
      }
    }

    return '';
  }
}
