import { Injectable } from '@angular/core';

import { IGeographicalBoundariesBody, IScopeTypeBody } from 'app/repository/models/geographical-boundaries.models';
import { GeographicalBoundariesService } from 'app/repository/services/geographical-boundaries/geographical-boundaries.service';

/**
 * GeographicalBoundariesApiService
 */
@Injectable({
  providedIn: 'root'
})
export class GeographicalBoundariesApiService {
  /**
   * Class constructor
   *
   * @param {GeographicalBoundariesService} geographicalBoundariesService create geo boundaries service
   */
  constructor(private geographicalBoundariesService: GeographicalBoundariesService) {}

  getScopeTypes(body: IScopeTypeBody) {
    return this.geographicalBoundariesService.getScopeTypes(body);
  }

  getGeographicalBoundaries(body: IGeographicalBoundariesBody) {
    return this.geographicalBoundariesService.getGeographicalBoundaries(body);
  }
}
