import { AfterViewInit, Directive, Input, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { LanguageSelectorService } from '@services/language-selector.service';

import { MSelectComponent } from '@mercadona/components/select';

export type MercadonaComponentType = MSelectComponent;

/**
 * TranslateLabelKeyDirective
 */
@Directive({
  selector: '[app-translate-label-key]'
})
export class TranslateLabelKeyDirective implements AfterViewInit, OnDestroy {
  @Input() componentRef!: MercadonaComponentType;
  @Input() translateLabelKeyFunction!: (componentRef: MercadonaComponentType, currentLang: string) => void;
  /**
   * Subject to unsubscribe
   */
  unsubscribe$ = new Subject<void>();

  /**
   * Class constructor
   *
   * @param {LanguageSelectorService} languageSelectorService languageSelectorService
   */
  constructor(public languageSelectorService: LanguageSelectorService) {}
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngAfterViewInit(): void {
    this.languageSelectorService.bSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((currentLang) => {
      this.translateLabelKeyFunction.bind(this)(this.componentRef, currentLang);
    });
  }
}

/**
 * mSelectTranslateLabelKey function
 *
 * @param {MSelectComponent} componentRef componentRef
 * @param {string} currentLang currentLang
 */
export function mSelectTranslateLabelKey(componentRef: MSelectComponent, currentLang: string) {
  componentRef.valueToShow = componentRef.value ? componentRef.value[currentLang] : componentRef.valueToShow;
}

/**
 * mSelectTranslateLabelKeyMultiple function
 *
 * @param {MSelectComponent} componentRef componentRef
 * @param {string} currentLang currentLang
 */
export function mSelectTranslateLabelKeyMultiple(componentRef: MSelectComponent, currentLang: string) {
  let newValueToShow = componentRef.values.reduce((res, el) => {
    res += el[currentLang] + ', ';
    return res;
  }, '');
  newValueToShow = newValueToShow.length > 0 ? newValueToShow.slice(0, -2) : newValueToShow;
  componentRef.valueToShow = newValueToShow.length > 0 ? newValueToShow : componentRef.valueToShow;
}
