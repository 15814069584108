import { ElementRef, Injectable, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin, map } from 'rxjs';

import {
  mSelectTranslateLabelKey,
  mSelectTranslateLabelKeyMultiple
} from '@directives/translate-label-key/translate-label-key.directive';
import { ISelectable } from '@interfaces/selectable.interface';
import { IGeographyService } from '@pages/new-need/components/product-implementation/services/product-implementation.service';
import { LanguageSelectorService } from '@services/language-selector.service';
import { ROLES } from 'app/constants/auth.interface';
import { matchLocaleLanguage } from 'app/functions/match-locale-language.function';
import {
  IScopeType,
  IScopeTypeBody,
  IGeographicalBoundariesBody
} from 'app/repository/models/geographical-boundaries.models';
import {
  IGeographyImplantationResponse,
  IGeographyRuleBody,
  INegotiationResponse
} from 'app/repository/models/geography.models';
import { IArticle, IArticleCharacteristics } from 'app/repository/models/product.models';
import { FirstLetterCapitalizePipe } from 'app/repository/pipes/first-letter-capitalize.pipe';
import { NameLanguagePipe } from 'app/repository/pipes/name-language.pipe';
import { NumberNullType, NumberNullUndefinedType } from 'app/types/global.types';
import { GeographyType } from 'app/types/product-implementation.types';

import { MAuthService } from '@mercadona/core/auth';
import { MTranslateService } from '@mercadona/core/translate';
import { MIconComponent } from '@mercadona/icons';

import { GeographicalBoundariesApiService } from '../geographical-boundaries-api/geographical-boundaries-api.service';
type HelperClassType = string | number | boolean | null | undefined;

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  generatedDescriptions: { [key: string]: string } = {};

  constructor(
    private mAuthService: MAuthService,
    private nameLanguagePipe: NameLanguagePipe,
    private mTranslateService: MTranslateService,
    private languageSelectorService: LanguageSelectorService,
    private geographicalBoundariesApiService: GeographicalBoundariesApiService
  ) {}
  get home() {
    return window.location.pathname.includes('/cdv90/supplier') ? '/supplier' : '/';
  }

  onHoverDivContainer(renderer: Renderer2, thirdColumnInfo: HTMLDivElement, iconGo: MIconComponent) {
    renderer.removeClass(thirdColumnInfo, 'unsided');
    renderer.addClass(thirdColumnInfo, 'sided');
    const el = iconGo as unknown as { element: ElementRef };
    renderer.removeClass(el.element.nativeElement, 'hidden-go');
    renderer.addClass(el.element.nativeElement, 'showable-go');
  }

  onRemoveHoverDivContainer(renderer: Renderer2, thirdColumnInfo: HTMLDivElement, iconGo: MIconComponent) {
    renderer.addClass(thirdColumnInfo, 'unsided');
    renderer.removeClass(thirdColumnInfo, 'sided');
    const el = iconGo as unknown as { element: ElementRef };
    renderer.addClass(el.element.nativeElement, 'hidden-go');
    renderer.removeClass(el.element.nativeElement, 'showable-go');
  }

  trackByFn = (_index: number, item: unknown) => item;

  get rolesGetter() {
    return ROLES;
  }

  get mSelectTranslateLabelKey() {
    return mSelectTranslateLabelKey;
  }

  get mSelectTranslateLabelKeyMultiple() {
    return mSelectTranslateLabelKeyMultiple;
  }

  getIconFormat(type: string) {
    let icon: string = '';
    const prefix = type.charAt(0).toUpperCase();

    switch (prefix) {
      case 'C':
        icon = 'custom/box-bold';
        break;

      case 'P':
        icon = 'custom/palet-bold';
        break;

      case 'H':
        icon = 'custom/medio-palet-bold';
        break;

      case 'L':
        icon = 'bold/food-drinks/water/water-bottle-glass';
        break;
      default:
        icon = 'bold/food-drinks/water/water-bottle-glass';
        break;
    }

    return icon;
  }

  generateDescription(item: IArticle, useHtml: boolean = false): string {
    const itemId = item.articleId;
    const cacheKey = useHtml ? `${itemId}_html` : `${itemId}_plain`;

    if (this.generatedDescriptions[cacheKey]) {
      return this.generatedDescriptions[cacheKey];
    }

    let description = '';

    item.characteristics.forEach((characteristic: IArticleCharacteristics) => {
      const characteristicName =
        characteristic.characteristicAbbreviation ??
        this.nameLanguagePipe.transform(characteristic.characteristicNames);
      const valuesDescription = characteristic.value.valueName;

      description += `${characteristicName}: ${useHtml ? '<span class="body03-semibold">' : ''}${valuesDescription}${
        useHtml ? '</span>' : ''
      } `;
    });

    this.generatedDescriptions[cacheKey] = description.trim();

    return this.generatedDescriptions[cacheKey];
  }

  getResume(geographies: GeographyType[]) {
    let firstCapitalized = true;
    return geographies.reduce((res: string, el: GeographyType) => {
      if (!firstCapitalized) {
        res = res + this.mTranslateService.translate('product-implementation.and');
      }
      res = this.resumeInclusion(el, res, firstCapitalized);
      res = res + this.resumeExclusion(el);
      firstCapitalized = false;
      return res;
    }, '' as string);
  }

  resumeInclusion(el: GeographyType, res: string, firstCapitalized: boolean) {
    const plural = el.inclusions.length > 1;
    const allInclusionSelected = el.areAllValuesSelected;
    const scopeTypeName = this.resumeScopeType(firstCapitalized, el.scopeType.id, plural);

    if (allInclusionSelected) {
      if (firstCapitalized) {
        res = res + this.resumeScopeTypeAll(el.scopeType.id, '-c');
      } else {
        res = res + this.resumeScopeTypeAll(el.scopeType.id, '');
      }
    } else {
      let listInclusions = '';
      let count = 1;

      el.inclusions.forEach((item) => {
        let inclusionName = item.names.find((eee) =>
          matchLocaleLanguage(eee.localeLanguage, this.languageSelectorService.bSubject.value)
        )?.name;
        if (inclusionName) {
          inclusionName = new FirstLetterCapitalizePipe().transform(inclusionName, true);
        }
        if (count > 1 && count === el.inclusions.length) {
          listInclusions =
            listInclusions + this.mTranslateService.translate('product-implementation.and') + inclusionName;
        } else if (count > 1) {
          listInclusions = listInclusions + ', ' + inclusionName;
        } else {
          listInclusions = listInclusions + inclusionName;
        }
        count += 1;
      });

      res = res + scopeTypeName + listInclusions;
    }

    return res;
  }

  resumeExclusion(el: GeographyType) {
    let res = '';

    el.exclusion.forEach((item) => {
      const allExclusionSelected = item.areAllValuesSelected;
      if (allExclusionSelected) {
        res = res + this.mTranslateService.translate('product-implementation.minus');
        res = res + this.resumeScopeTypeAll(item.scopeType.id, '');
      } else {
        const plural = item.exclusions.length > 1;
        const scopeTypeName = this.resumeScopeType(false, item.scopeType.id, plural);
        let listExclusions = this.mTranslateService.translate('product-implementation.minus');
        listExclusions = listExclusions + scopeTypeName;
        let count = 1;
        item.exclusions.forEach((item2) => {
          let exclusionName = item2.names.find((eee) =>
            matchLocaleLanguage(eee.localeLanguage, this.languageSelectorService.bSubject.value)
          )?.name;
          if (exclusionName) {
            exclusionName = new FirstLetterCapitalizePipe().transform(exclusionName, true);
          }
          if (count > 1 && count === item.exclusions.length) {
            listExclusions =
              listExclusions + this.mTranslateService.translate('product-implementation.and') + exclusionName;
          } else if (count > 1) {
            listExclusions = listExclusions + ', ' + exclusionName;
          } else {
            listExclusions = listExclusions + exclusionName;
          }
          count += 1;
        });
        res = res + listExclusions;
      }
    });

    return res;
  }

  resumeScopeType(firstCapitalized: boolean, id: number, plural: boolean) {
    switch (id) {
      case 1: // Country
        return this.resumeScopeTypeCountry(firstCapitalized, plural);
      case 2: // CA
        return this.resumeScopeTypeCA(firstCapitalized, plural);
      case 3: // Province
        return this.resumeScopeTypeProvince(firstCapitalized, plural);
      case 4: // Logistic Block
        return this.resumeScopeTypeLogisticBlock(firstCapitalized, plural);
      case 5: // Work Area
        return this.resumeScopeTypeWorkArea(firstCapitalized, plural);
      case 6: // Comercial Area
        return this.resumeScopeTypeComercialArea(firstCapitalized, plural);
      case 7: // Centers
        return this.resumeScopeTypeCenter(firstCapitalized, plural);
      case 11: // Districts
        return this.resumeScopeTypeDistrict(firstCapitalized, plural);
      default: // None
        return 'geoBoundaryType not found';
    }
  }

  resumeScopeTypeAll(id: number, cap: string) {
    switch (id) {
      case 1: // Country
        return this.mTranslateService.translate('product-implementation.p-all-countries' + cap);
      case 2: // CA
        return this.mTranslateService.translate('product-implementation.p-all-autonomous-communities' + cap);
      case 3: // Province
        return this.mTranslateService.translate('product-implementation.p-all-province' + cap);
      case 4: // Logistic Block
        return this.mTranslateService.translate('product-implementation.p-all-logistic-block' + cap);
      case 5: // Work Area
        return this.mTranslateService.translate('product-implementation.p-all-work-area' + cap);
      case 6: // Comercial Area
        return this.mTranslateService.translate('product-implementation.p-all-comercial-area' + cap);
      case 7: // Centers
        return this.mTranslateService.translate('product-implementation.p-all-center' + cap);
      case 11: // Districts
        return this.mTranslateService.translate('product-implementation.p-all-districts' + cap);
      default: // None
        return 'geoBoundaryType not found';
    }
  }

  resumeScopeTypeCountry(firstCapitalized: boolean, plural: boolean) {
    if (firstCapitalized) {
      if (plural) {
        return this.mTranslateService.translate('product-implementation.p-countries-c');
      }
      return this.mTranslateService.translate('product-implementation.p-country-c');
    }
    if (plural) {
      return this.mTranslateService.translate('product-implementation.p-countries');
    }
    return this.mTranslateService.translate('product-implementation.p-country');
  }

  resumeScopeTypeCA(firstCapitalized: boolean, plural: boolean) {
    if (firstCapitalized) {
      if (plural) {
        return this.mTranslateService.translate('product-implementation.p-autonomous-communities-c');
      }
      return this.mTranslateService.translate('product-implementation.p-autonomous-community-c');
    }
    if (plural) {
      return this.mTranslateService.translate('product-implementation.p-autonomous-communities');
    }
    return this.mTranslateService.translate('product-implementation.p-autonomous-community');
  }

  resumeScopeTypeProvince(firstCapitalized: boolean, plural: boolean) {
    if (firstCapitalized) {
      if (plural) {
        return this.mTranslateService.translate('product-implementation.p-provinces-c');
      }
      return this.mTranslateService.translate('product-implementation.p-province-c');
    }
    if (plural) {
      return this.mTranslateService.translate('product-implementation.p-provinces');
    }
    return this.mTranslateService.translate('product-implementation.p-province');
  }

  resumeScopeTypeLogisticBlock(firstCapitalized: boolean, plural: boolean) {
    if (firstCapitalized) {
      if (plural) {
        return this.mTranslateService.translate('product-implementation.p-logistic-blocks-c');
      }
      return this.mTranslateService.translate('product-implementation.p-logistic-block-c');
    }
    if (plural) {
      return this.mTranslateService.translate('product-implementation.p-logistic-blocks');
    }
    return this.mTranslateService.translate('product-implementation.p-logistic-block');
  }

  resumeScopeTypeWorkArea(firstCapitalized: boolean, plural: boolean) {
    if (firstCapitalized) {
      if (plural) {
        return this.mTranslateService.translate('product-implementation.p-work-areas-c');
      }
      return this.mTranslateService.translate('product-implementation.p-work-area-c');
    }
    if (plural) {
      return this.mTranslateService.translate('product-implementation.p-work-areas');
    }
    return this.mTranslateService.translate('product-implementation.p-work-area');
  }

  resumeScopeTypeComercialArea(firstCapitalized: boolean, plural: boolean) {
    if (firstCapitalized) {
      if (plural) {
        return this.mTranslateService.translate('product-implementation.p-comercial-areas-c');
      }
      return this.mTranslateService.translate('product-implementation.p-comercial-area-c');
    }
    if (plural) {
      return this.mTranslateService.translate('product-implementation.p-comercial-areas');
    }
    return this.mTranslateService.translate('product-implementation.p-comercial-area');
  }

  resumeScopeTypeCenter(firstCapitalized: boolean, plural: boolean) {
    if (firstCapitalized) {
      if (plural) {
        return this.mTranslateService.translate('product-implementation.p-centers-c');
      }
      return this.mTranslateService.translate('product-implementation.p-center-c');
    }
    if (plural) {
      return this.mTranslateService.translate('product-implementation.p-centers');
    }
    return this.mTranslateService.translate('product-implementation.p-center');
  }

  resumeScopeTypeDistrict(firstCapitalized: boolean, plural: boolean) {
    if (firstCapitalized) {
      if (plural) {
        return this.mTranslateService.translate('product-implementation.p-districts-c');
      }
      return this.mTranslateService.translate('product-implementation.p-district-c');
    }
    if (plural) {
      return this.mTranslateService.translate('product-implementation.p-districts');
    }
    return this.mTranslateService.translate('product-implementation.p-district');
  }

  reCalculateResume(this: IGeographyService) {
    if ((this.geographies || []).length > 0 && (this.originalGeographiesResume || '').length === 0) {
      const reCalculatedResume = `${this.resume}`;
      if (this.originalGeographiesResume !== reCalculatedResume) {
        this.originalGeographiesResume = reCalculatedResume;
      }
    }
  }

  assignGeographyImplantation(
    this: IGeographyService,
    implantation: INegotiationResponse | IGeographyImplantationResponse | undefined
  ) {
    if (!implantation) {
      this.geographies.splice(0, this.geographies.length);
      if (this.originalGeographiesResume !== this.resume) {
        this.originalGeographiesResume = `${this.resume}`;
      }

      return;
    }
    this.geographies.splice(0, this.geographies.length);
    implantation.data.geographicalRules.forEach((el) => {
      const obj = {
        scopeType: el.scopeType,
        inclusions: el.inclusions.map((e) => ({ ...e, selected: true } as ISelectable<IScopeType>)),
        exclusion: el.exclusions.reduce(
          (ress, ell) => {
            const objj = {
              scopeType: ell.scopeType,
              areAllValuesSelected: ell.hasAllScopeType,
              exclusions: ell.exclusions.map((e) => ({ ...e, selected: true } as ISelectable<IScopeType>))
            };
            ress.push(objj);
            return ress;
          },
          [] as {
            scopeType: IScopeType;
            exclusions: ISelectable<IScopeType>[];
            areAllValuesSelected: boolean;
          }[]
        ),
        areAllValuesSelected: el.hasAllScopeType
      };
      this.geographies.push(obj);
    });
    if (this.originalGeographiesResume !== this.resume) {
      this.originalGeographiesResume = `${this.resume}`;
    }
  }

  getScopeType(
    body: IScopeTypeBody = {
      data: {
        editScope: false,
        scopeTypes: []
      }
    }
  ) {
    return this.geographicalBoundariesApiService.getScopeTypes(body);
  }

  getGeographicalBoundaries(body: IGeographicalBoundariesBody) {
    return this.geographicalBoundariesApiService.getGeographicalBoundaries(body);
  }

  transformGeographyRule(el: GeographyType): IGeographyRuleBody {
    let obj: IGeographyRuleBody = {
      scopeType: el.scopeType.id,
      hasAllScopeType: el.areAllValuesSelected,
      exclusions: el.exclusion.reduce(
        (ress, ell) => {
          const objj = {
            scopeType: ell.scopeType.id,
            hasAllScopeType: ell.areAllValuesSelected,
            exclusions: ell.exclusions.map(({ selected: _, ...rest }) => String(rest.id))
          };
          ress.push(objj);
          return ress;
        },
        [] as {
          scopeType: number;
          hasAllScopeType: boolean;
          exclusions?: string[];
        }[]
      )
    } as unknown as IGeographyRuleBody;

    if (!obj.hasAllScopeType) {
      obj.inclusions = el.inclusions.map(({ selected: _, ...rest }) => String(rest.id));
    }

    if (obj.exclusions.length === 0) {
      const { exclusions: _, ...newObj } = obj;
      obj = newObj as unknown as IGeographyRuleBody;
    }

    return obj;
  }

  normalizeText(text: string): string {
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  showPending(w: string | number | boolean | null | undefined): boolean {
    if (w === null || w === undefined) {
      return true;
    }
    return false;
  }

  showPendingValue(
    w: string | number | boolean | null | undefined,
    adding: string,
    isBool: boolean,
    pending: string = 'product.pendingSupplier'
  ): string | number | boolean {
    if (w === null || w === undefined) {
      return '<span class="helper-italic text03">' + this.mTranslateService.translate(pending) + '</span>';
    }
    if (!isBool) {
      return (
        '<span class="body02-regular text04 mt-2 truncate-text">' +
        w +
        ' ' +
        this.mTranslateService.translate(adding) +
        '</span>'
      );
    }
    return (
      '<span class="body02-regular text04 mt-2 truncate-text">' +
      this.mTranslateService.translate(`boolean-translation.${w}`) +
      '</span>'
    );
  }

  addHelperClass(w: HelperClassType): boolean {
    if (w === null || w === undefined || w === '') {
      return true;
    }
    return false;
  }

  getLocationPathname() {
    return window.location.pathname;
  }

  getPermissionProviderObs$(this: unknown & { mAuthService: MAuthService }) {
    return forkJoin([
      this.mAuthService.getPermissions(),
      this.mAuthService.getToken().pipe(
        map((res) => res.accessTokenClaims?.extension_ApplicationName === ROLES.proveedorB2C),
        map((b) => (b ? ROLES.proveedorB2C : null))
      )
    ]).pipe(
      map(([a, b]: [string[], string | null]) => a.concat(b === null ? [] : [b])),
      map((res) => res)
    );
  }

  valueFormatChanges(
    isBase: boolean,
    field: string,
    tare: NumberNullType,
    net: NumberNullType,
    gross: NumberNullType,
    f: FormGroup,
    pGW: number
  ) {
    switch (isBase + ',' + field) {
      case 'true,TARE':
        this.valueFormatBaseTare(tare, net, gross, f);
        break;
      case 'true,NET':
        this.valueFormatBaseNet(tare, net, gross, f);
        break;
      case 'true,GROSS':
        this.valueFormatBaseGross(tare, net, gross, f);
        break;
      case 'false,TARE':
        this.valueFormatTare(tare, f, pGW);
        break;
      case 'false,GROSS':
        this.valueFormatGross(gross, f, pGW);
        break;
      case 'false,UNITS':
        this.valueFormatUnits(tare, f, pGW);
        break;
    }
  }

  valueFormatBaseTare(tare: NumberNullType, net: NumberNullType, gross: NumberNullType, f: FormGroup) {
    if (tare === null) {
      this.valueFormatBaseTareNull(tare, net, gross, f);
    } else if (net !== null) {
      f.get('grossWeight')?.setValue(tare + net);
    } else if (gross !== null && gross >= tare) {
      f.get('netWeight')?.setValue(gross - tare);
    } else if (gross !== null) {
      f.get('grossWeight')?.setValue(tare);
    }
  }

  valueFormatBaseTareNull(tare: NumberNullType, net: NumberNullType, gross: NumberNullType, f: FormGroup) {
    if (net !== null && gross !== null && gross >= net) {
      f.get('tare')?.setValue(gross - net);
    } else if (net !== null && gross !== null) {
      f.get('grossWeight')?.setValue(net);
    }
  }

  valueFormatBaseNet(tare: NumberNullType, net: NumberNullType, gross: NumberNullType, f: FormGroup) {
    if (net === null) {
      this.valueFormatBaseNetNull(tare, net, gross, f);
    } else if (tare !== null) {
      f.get('grossWeight')?.setValue(tare + net);
    } else if (gross !== null && gross >= net) {
      f.get('tare')?.setValue(gross - net);
    } else if (gross !== null) {
      f.get('grossWeight')?.setValue(net);
    }
  }

  valueFormatBaseNetNull(tare: NumberNullType, net: NumberNullType, gross: NumberNullType, f: FormGroup) {
    if (tare !== null && gross !== null && gross >= tare) {
      f.get('netWeight')?.setValue(gross - tare);
    } else if (tare !== null && gross !== null) {
      f.get('grossWeight')?.setValue(tare);
    }
  }

  valueFormatBaseGross(tare: NumberNullType, net: NumberNullType, gross: NumberNullType, f: FormGroup) {
    if (gross === null) {
      this.valueFormatBaseGrossNull(tare, net, f);
    } else if (tare !== null) {
      // && tare <= gross) {
      f.get('netWeight')?.setValue(gross - tare);
    } else if (net !== null && net <= gross) {
      f.get('tare')?.setValue(gross - net);
    } else if (net !== null) {
      f.get('tare')?.setValue(gross - net);
    }
  }

  valueFormatBaseGrossNull(tare: NumberNullType, net: NumberNullType, f: FormGroup) {
    if (tare !== null || net !== null) {
      f.get('grossWeight')?.setValue((tare ?? 0) + (net ?? 0));
    }
  }

  valueFormatTare(tare: NumberNullType, f: FormGroup, pGW: NumberNullUndefinedType) {
    const vUnits = f.get('units')?.value;
    if (pGW !== null && pGW !== undefined && vUnits !== null && tare !== null) {
      f.get('grossWeight')?.setValue(vUnits * pGW + tare);
    }
  }

  valueFormatGross(gross: NumberNullType, f: FormGroup, pGW: NumberNullUndefinedType) {
    const vUnits = f.get('units')?.value;
    if (pGW !== null && pGW !== undefined && vUnits !== null && gross !== null) {
      f.get('tare')?.setValue(gross - vUnits * pGW);
    }
  }

  valueFormatUnits(tare: NumberNullType, f: FormGroup, pGW: number) {
    const vUnits = f.get('units')?.value;
    if (pGW !== null && vUnits !== null && tare !== null) {
      f.get('grossWeight')?.setValue(vUnits * pGW + tare);
    } else if (pGW !== null && vUnits !== null) {
      f.get('grossWeight')?.setValue(vUnits * pGW);
    }
    if (vUnits === 1) {
      f.get('mantlesPalette')?.setValue(1);
      f.get('boxesMantle')?.setValue(1);
    }
  }
}
