import { Pipe, PipeTransform } from '@angular/core';

import { StringNullUndefinedType } from 'app/types/global.types';

@Pipe({
  name: 'emptyImgProfile'
})
export class EmptyImgProfilePipe implements PipeTransform {
  private readonly emptyImgSrc = './../../../assets/icons/user.png';
  transform(value: StringNullUndefinedType) {
    return value || this.emptyImgSrc;
  }
}
