/**
 *
 * @param {string} locale locale
 * @param {string} selected selected
 * @returns {boolean} res res
 */
export function matchLocaleLanguage(locale: string, selected: string) {
  let tmp = locale;
  tmp = tmp.split('-').join('_');
  const b1 = tmp === selected;
  tmp = tmp.split('_').join('-');
  const b2 = tmp === selected;
  return b1 || b2;
}
