import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BooleanTranslationPipe } from './boolean-translation.pipe';
import { EmptyImgProfilePipe } from './empty-img-profile.pipe';
import { EmptyImgPipe } from './empty-img.pipe';
import { FirstLetterCapitalizePipe } from './first-letter-capitalize.pipe';
import { HierarchyFormatterPipe } from './hierarchy-formatter.pipe';
import { InitialNamePipe } from './initial-name.pipe';
import { LifeTypePipe } from './life-type.pipe';
import { NameLanguagePipe } from './name-language.pipe';
import { UnderscoreEmptyTextPipe } from './underscore-empty-text.pipe';

@NgModule({
  declarations: [
    EmptyImgProfilePipe,
    LifeTypePipe,
    InitialNamePipe,
    NameLanguagePipe,
    FirstLetterCapitalizePipe,
    EmptyImgPipe,
    HierarchyFormatterPipe,
    UnderscoreEmptyTextPipe,
    BooleanTranslationPipe
  ],
  imports: [CommonModule],
  exports: [
    EmptyImgProfilePipe,
    LifeTypePipe,
    InitialNamePipe,
    NameLanguagePipe,
    FirstLetterCapitalizePipe,
    EmptyImgPipe,
    HierarchyFormatterPipe,
    UnderscoreEmptyTextPipe,
    BooleanTranslationPipe
  ]
})
export class PipeModule {}
