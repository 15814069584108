import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  IChartsResponse,
  ILastMovementsResponse,
  INeedsListResponse,
  IProductsListResponse
} from 'app/repository/models/home.models';
import { HomeService } from 'app/repository/services/home/home.service';
import { NumberNullUndefinedType, NumberStringNullUndefinedType } from 'app/types/global.types';

export type GetProductListRequestBodyType = {
  search: string;
  pageNumber: number;
  pageSize: number;
  statusId?: NumberNullUndefinedType;
  costCenterId?: NumberStringNullUndefinedType;
  supplierId?: string;
  productWithoutNegotiation?: boolean | null;
  onlyMMFF?: boolean;
  excludeVariableArticleId?: string;
  onlyKit?: boolean;
  missingTaxInfo?: boolean;
  missingAccountingInfo?: boolean;
  pending?: boolean;
};

export abstract class HomeApiServiceAbstraction {
  constructor(protected homeService: HomeService) {}

  getLastMovements(pageNumber: number, pageSize: number): Observable<ILastMovementsResponse> {
    return this.homeService.getLastMovements(pageNumber, pageSize);
  }
}

export interface IHomeApiService {
  getChartsHome: () => Observable<IChartsResponse>;
  getProductsList: (body: GetProductListRequestBodyType, isSupplierExt: boolean) => Observable<IProductsListResponse>;
  getLastMovements: (pageNumber: number, pageSize: number) => Observable<ILastMovementsResponse>;
}

@Injectable({
  providedIn: 'root'
})
export class HomeApiService extends HomeApiServiceAbstraction implements IHomeApiService {
  constructor(homeService: HomeService) {
    super(homeService);
  }

  getChartsHome(): Observable<IChartsResponse> {
    return this.homeService.getChartsHome();
  }

  getNeedsList(
    search: string,
    pageNumber: number,
    pageSize: number,
    statusId?: NumberNullUndefinedType,
    costCenterId?: NumberStringNullUndefinedType,
    isCovered?: boolean
  ): Observable<INeedsListResponse> {
    return this.homeService.getNeedsList(search, pageNumber, pageSize, statusId, costCenterId, isCovered);
  }

  getProductsList(body: GetProductListRequestBodyType, isSupplierExt: boolean) {
    return this.homeService.getProductsList(body, isSupplierExt);
  }
}
