import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * LanguageSelectorService
 */
@Injectable({
  providedIn: 'root'
})
export class LanguageSelectorService {
  bSubject!: BehaviorSubject<string>;
  languages = [
    {
      title: 'es_ES',
      selected: true
    },
    {
      title: 'pt_PT',
      selected: false
    }
  ];
  /**
   * Class constructor
   */
  constructor() {
    this.bSubject = new BehaviorSubject<string>('es_ES');
  }

  nextValue(value: string) {
    this.bSubject.next(value);
  }

  get currentLanguage$() {
    return this.bSubject.asObservable();
  }
}
